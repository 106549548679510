import React from 'react';
import { graphql } from 'gatsby';
import { dissectTitle } from '../helpers/general';

import Layout from '../components/organisms/Layout/Layout';
import SEO from '../components/organisms/Seo/Seo';
import Product from '../pages/Product';

export const query = graphql`
  query ProductTemplateQuery(
    $id: String!
    $bigcommerce_id: Int!
    $designName: String!
    $sku: String!
    $categoryId: Int!
    $relatedProducts: [Int!]
  ) {
    product: allBigCommerceProducts(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          entityId: bigcommerce_id
          sku
          name
          price
          calculated_price
          retail_price
          sale_price
          map_price
          description
          brand_id
          weight
          height
          width
          depth
          categories
          page_title
          meta_description
          related_products
          warranty
          variants {
            product_id
            entityId: id
            price
            sale_price
            calculated_price: calculated_price
            inventory_level
            option_values {
              id
              label
              option_display_name
              option_id
            }
          }
          custom_url {
            url
          }
          images {
            url_standard
            url_thumbnail
            url_zoom
            id
            description
            image_file
            is_thumbnail
            sort_order
          }

          inventory_level
          inventory_tracking
          inventory_warning_level
          availability
          availability_description
          preorder_release_date(formatString: "DD MMMM, YYYY")
          preorder_message
          reviews_count
          reviews_rating_sum
          custom_fields {
            name
            value
          }
        }
      }
    }
    relatedProducts: allBigCommerceProducts(
      filter: { bigcommerce_id: { in: $relatedProducts } }
      sort: { order: ASC, fields: sort_order }
    ) {
      edges {
        node {
          id
          entityId: bigcommerce_id
          sku
          name
          price
          calculated_price
          retail_price
          sale_price
          map_price
          description
          brand_id
          weight
          categories
          page_title
          related_products
          warranty
          custom_url {
            url
          }
          images {
            url_standard
            url_thumbnail
            url_zoom
            id
            description
            image_file
            is_thumbnail
          }
          variants {
            product_id
            entityId: id
          }
          inventory_level
          inventory_tracking
          inventory_warning_level
          availability
          availability_description
          reviews_count
          reviews_rating_sum
          custom_fields {
            name
            value
          }
        }
      }
    }
    completeTheLook: allBigCommerceProducts(
      filter: {
        sku: { ne: $sku }
        custom_fields: {
          elemMatch: { name: { eq: "Design" }, value: { eq: $designName } }
        }
      }
    ) {
      edges {
        node {
          id
          entityId: bigcommerce_id
          sku
          name
          price
          calculated_price
          retail_price
          sale_price
          map_price
          description
          brand_id
          weight
          categories
          page_title
          related_products
          warranty
          custom_url {
            url
          }
          images {
            url_standard
            url_thumbnail
            url_zoom
            id
            description
            image_file
            is_thumbnail
          }
          variants {
            product_id
            entityId: id
          }
          inventory_level
          inventory_tracking
          inventory_warning_level
          availability
          availability_description
          reviews_count
          reviews_rating_sum
          custom_fields {
            name
            value
          }
        }
      }
    }
    youMayAlsoLike: allBigCommerceProducts(
      filter: { sku: { ne: $sku }, categories: { eq: $categoryId } }
    ) {
      edges {
        node {
          id
          entityId: bigcommerce_id
          sku
          name
          price
          calculated_price
          retail_price
          sale_price
          map_price
          description
          brand_id
          weight
          categories
          page_title
          related_products
          warranty
          custom_url {
            url
          }
          images {
            url_standard
            url_thumbnail
            url_zoom
            id
            description
            image_file
            is_thumbnail
          }
          variants {
            product_id
            entityId: id
          }
          inventory_level
          inventory_tracking
          inventory_warning_level
          availability
          availability_description
          reviews_count
          reviews_rating_sum
          custom_fields {
            name
            value
          }
        }
      }
    }
    productPrices: allBigCommerceProductPricing(
      filter: { product_id: { eq: $bigcommerce_id } }
    ) {
      edges {
        node {
          price {
            as_entered
            entered_inclusive
            tax_exclusive
            tax_inclusive
          }
          retail_price {
            as_entered
            entered_inclusive
            tax_exclusive
            tax_inclusive
          }
          sale_price {
            is_on_sale
            as_entered
            entered_inclusive
            tax_exclusive
            tax_inclusive
          }
          calculated_price {
            as_entered
            entered_inclusive
            tax_exclusive
            tax_inclusive
          }
        }
      }
    }
    categories: allBigCommerceCategories {
      edges {
        node {
          bigcommerce_id
          name
          parent_id
          sort_order
          custom_url {
            url
            is_customized
          }
        }
      }
    }
  }
`;

const ProductTemplate = props => {
  const { data, errors } = props;
  const product = data && data.product.edges[0].node;

  const [, productName] = dissectTitle(product.name);

  product.categories = product.categories
    .map(cat_id => {
      if (typeof cat_id === 'number') {
        const cat = data.categories.edges
          .map(a => a.node)
          .filter(cat => cat.bigcommerce_id === cat_id);
        return cat.length
          ? {
              id: cat[0].bigcommerce_id,
              name: cat[0].name,
              parent_id: cat[0].parent_id,
              sort_order: cat[0].sort_order,
              url: cat[0].custom_url.url
            }
          : null;
      } else {
        return cat_id;
      }
    })
    .filter(a => a !== null);

  product.relatedProducts = data.relatedProducts.edges;
  product.completeTheLook = data.completeTheLook.edges;
  product.youMayAlsoLike = data.youMayAlsoLike.edges;
  product.detailedPrices = data.productPrices.edges[0].node;

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {product && (
        <SEO
          title={product.page_title || productName}
          description={product.meta_description}
        />
      )}

      {/* {errors && (
            <Container>
              <GraphQLErrorList errors={errors} />
            </Container>
        )} */}

      {product && <Product data={product} />}
    </Layout>
  );
};

export default ProductTemplate;
